import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirebaseFirestore } from '@capacitor-firebase/firestore';
import { Capacitor } from '@capacitor/core';
import AgoraRTC, {
  IAgoraRTCClient,
  LiveStreamingTranscodingConfig,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ScreenVideoTrackInitConfig,
  VideoEncoderConfiguration,
  AREAS,
  IRemoteAudioTrack,
  ClientRole
} from 'agora-rtc-sdk-ng';
import axios from 'axios';
import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';

export interface IUser {
  uid: number;
  name?: string;
}
export interface IRtc {
  client: IAgoraRTCClient;
  localAudioTrack: IMicrophoneAudioTrack;
  localVideoTrack: ICameraVideoTrack;
}

@Injectable({
  providedIn: 'root'
})
export class StreamService {
  rtc: IRtc = {
    // For the local client.
    client: null,
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null
  };
  options = {
    appId: '4f8e68057f444ef2b7ac44c58a793867', // set your appid here
    channel: 'Tony' // Set the channel name.
    // token:
    //   '007eJxTYGCcmdmnttGfxcmnJ1ayMyXYbV1655/uJu9rl9jTda2TIhUYTNIsUs0sDEzN00xMTFLTjJLME5NNTJJNLRLNLY0tzMxVpxunNQQyMkT+M2BmZIBAEJ+FISQ/r5KBAQBONhvw'
    // uid: null
  };
  currentCam = 'Front Camera';
  remoteUsers: IUser[] = []; // To add remote users in list
  updateUserInfo = new BehaviorSubject<any>(null); // to update remote users name
  liveUsersList = [];
  cams: MediaDeviceInfo[];
  audioUnits: MediaDeviceInfo[];
  concertDetails: any;

  constructor(private http: HttpClient) {}

  createRTCClient(type) {
    if (type == 'viewer') {
      this.rtc.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    } else {
      this.rtc.client = AgoraRTC.createClient({ mode: 'live', codec: 'h264' });
    }
  }

  requestRtcToken(uid: number, role: string) {
    const endpoint = 'http://localhost:3000/backend/concert-token'; // Backend endpoint for POST request
    const payload = { uid, role }; // Data to send in the POST request
    console.log(payload);
    return axios
      .post(endpoint, payload)
      .then((response: any) => {
        if (response) {
          console.log(response);
          return response;
        }
      })
      .catch((error) => {
        console.error('Error creating subscription:', error);
        // Handle subscription creation error
      });
  }

  async getAudio() {
    this.audioUnits = await AgoraRTC.getMicrophones();
    return this.audioUnits;
  }

  async setAudio(label) {
    const currentAudio = this.audioUnits.find((audio) => audio.label === label);
    console.log(this.audioUnits);
    console.log(currentAudio);
    await this.rtc.localAudioTrack.setDevice(currentAudio.deviceId);
  }

  async getCamera() {
    this.cams = await AgoraRTC.getCameras(); //  all cameras devices you can use
    return this.cams;
  }

  // comment it if you don't want virtual camera
  async switchCamera(label?: string) {
    if (!label) {
      this.cams = await AgoraRTC.getCameras();
      if (this.currentCam == 'Front Camera') {
        label = 'Back Camera';
        this.currentCam = 'Back Camera';
      } else {
        label = 'Front Camera';
        this.currentCam = 'Front Camera';
      }
    }
    console.log('label');
    const currentCam = this.cams.find((cam) => cam.label === label);
    await this.rtc.localVideoTrack.setDevice(currentCam.deviceId);
  }

  // To join a call with tracks (video or audio)
  async localUser(uuid, type) {
    const streamInfo = await this.getStreamInfo();
    console.log(streamInfo);
    if (type == 'viewer') {
      await this.rtc.client.setClientRole('audience');
    }
    await this.rtc.client.join(this.options.appId, this.options.channel, streamInfo.token, uuid);

    if (type != 'viewer') {
      await this.rtc.client.setClientRole('host');

      // Create an audio track from the audio sampled by a microphone.
      this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a video track from the video captured by a camera.
      this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: '1080p_2'
      });

      // comment it if you want to use your camera
      // this.switchCamera('Back Camera', this.rtc.localVideoTrack);
      // Publish the local audio and video tracks to the channel.
      // this.rtc.localAudioTrack.play();
      this.rtc.localVideoTrack.play('local-player');
      // channel for other users to subscribe to it.
      await this.rtc.client.publish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);
    }
  }

  async getStreamInfo() {
    const ref = `callables/livestream`;
    if (Capacitor.getPlatform() == 'android') {
      const db = getFirestore(getApp());
      const docRef = doc(db, ref);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        this.concertDetails = docSnap.data();
        return docSnap.data();
      } else {
        // docSnap.data() will be undefined in this case
      }
    } else {
      const { snapshot } = await FirebaseFirestore.getDocument({
        reference: ref
      });
      this.concertDetails = snapshot.data;
      return snapshot.data;
    }
  }

  getConcertInfo() {
    return this.concertDetails;
  }

  agoraServerEvents(rtc) {
    rtc.client.on('user-published', async (user, mediaType) => {
      console.log(user, mediaType, 'user-published');

      await rtc.client.subscribe(user, mediaType);
      if (mediaType === 'video') {
        const remoteVideoTrack = user.videoTrack;

        setTimeout(() => {
          remoteVideoTrack.play('remote-playerlist' + user.uid);
        }, 100);
      }
      if (mediaType === 'audio') {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
    });

    rtc.client.on('user-left', (user) => {
      console.log('user-left', user);
      const leavingUID = user.uid;

      // Check if the leaving user is the host
      if (leavingUID === 420) {
        console.log('Host has left. Ending session...');
        // Stop all audio and video streams
        rtc.client.leave(); // Leave the channel
        this.remoteUsers = []; // Clear remote users list
        this.liveUsersList = []; // Clear live users list
        this.updateUserInfo.next(null); // Notify about the session end
        // You can also trigger any additional cleanup or session-end logic here
      }
    });

    rtc.client.on('user-unpublished', (user) => {
      console.log(user, 'user-unpublished');
    });

    rtc.client.on('user-joined', (user) => {
      const id = user.uid;
      this.remoteUsers.push({ uid: +id });
      this.liveUsersList.push({ uid: +id });
      this.updateUserInfo.next(id);
      console.log('user-joined', user, this.remoteUsers, 'event1');
    });
  }

  leaveCallViewer() {
    this.rtc.client
      .leave()
      .then(() => {
        this.liveUsersList = [];
        console.log('Successfully left the call');
        // Optionally navigate to another page or perform cleanup
      })
      .catch((err) => {
        console.error('Error leaving the call:', err);
      });
  }

  // To leave channel-
  async leaveCall() {
    // Destroy the local audio and video tracks.
    this.rtc.localAudioTrack.close();
    this.rtc.localVideoTrack.close();
    // Traverse all remote users.
    this.rtc.client.remoteUsers.forEach((user) => {
      // Destroy the dynamically created DIV container.
      const playerContainer = document.getElementById('remote-playerlist' + user.uid.toString());
      playerContainer && playerContainer.remove();
    });
    // Leave the channel.
    await this.rtc.client.leave();
  }
}
