import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Browser } from '@capacitor/browser';
import axios from 'axios';
import { ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class SpotifyAuthService {
  private clientId = environment.spotifyClientId;
  private backendUrl = environment.backendUrl;
  private redirectUri = 'com.toekneetee1.app://callback';
  // private redirectUri = 'http://localhost:8100/tabs/music';
  user: any;
  private scopes = 'playlist-modify-public playlist-modify-private user-library-modify';
  private accessToken: string;
  private accessTokenSubject = new BehaviorSubject<string>(null);
  musicURI: string;
  type: string;
  constructor(private http: HttpClient, private toastController: ToastController) {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  getAccessToken(): string {
    return this.accessToken;
  }

  authenticate(URI, type: string): void {
    const authUrl = `https://accounts.spotify.com/authorize?client_id=${
      this.clientId
    }&redirect_uri=${encodeURIComponent(this.redirectUri)}&scope=${encodeURIComponent(this.scopes)}&response_type=code`;
    Browser.open({ url: authUrl });
    this.musicURI = URI;
    this.type = type;

    // window.location.href = authUrl;
  }

  async exchangeAuthorizationCode(code: string) {
    const refreshToken = localStorage.getItem('refresh_Token');

    try {
      const response = await axios.post(`${this.backendUrl}/auth/spotify`, {
        code: code,
        user: this.user?.uid,
        musicURI: this.musicURI,
        clientId: this.clientId,
        type: this.type
      });

      if (!refreshToken) {
        localStorage.setItem('refresh_Token', response.data);
      }

      this.presentToast();

      return response.data;
    } catch (error) {
      console.error('Error exchanging authorization ,code:', error);
      throw error;
    }
  }

  async refreshAccessToken(refreshToken: string, type: string, albumId: string) {
    try {
      //this backend function also saves the album or song
      const response = await axios.post(`${this.backendUrl}/auth/spotify/refresh`, {
        refreshToken: refreshToken,
        clientId: this.clientId,
        type: type,
        albumId: albumId
      });
      if ((response.status = 200)) this.presentToast();
      return response;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      throw error;
    }
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: 'This song has been added to your liked songs on Spotify',
      duration: 2000, // Duration in milliseconds
      position: 'bottom', // Position can be 'top', 'bottom', or 'middle'
      color: 'primary' // You can change the color to match your app's theme
    });

    toast.present();
  }

  getAccessTokenObservable() {
    return this.accessTokenSubject.asObservable();
  }
}
