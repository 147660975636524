import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { VidModalPage } from '../vid-modal/vid-modal.page';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../globals';
import { MusicPlayer } from '../core/musicPlayer.services';
import { environment } from 'src/environments/environment';
import { StreamService } from '../components/concertMode/stream.service';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../components/concertMode/common.service';

// import { BackgroundMode } from '@ionic-native/background-mode/ngx';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.page.html',
  styleUrls: ['./videos.page.scss']
})
export class VideosPage implements OnInit {
  categories = [
    {
      title: 'Live Shows',
      videos: { items: [] }
    },

    {
      title: 'Music Vids',
      videos: { items: [] }
    },

    {
      title: 'Interviews',
      videos: { items: [] }
    },

    {
      title: 'Podcast',
      videos: { items: [] }
    }
  ];

  opts = {
    slidesPerView: 2.4,
    slidesOffsetBefore: 20,
    spaceBetween: 20,
    freeMode: true
  };
  streamingInfo: any;
  tier: string;
  watching: boolean = false;

  constructor(
    public modalController: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private plt: Platform,
    private globals: GlobalVariable,
    private musicPlayer: MusicPlayer,
    public stream: StreamService,
    public auth: AuthService,
    private common: CommonService
  ) {}

  key = 'd46787d8e471fb4e3216fb4dd001bffe';
  url = 'https://www.googleapis.com/youtube/v3/playlistItems';
  clip: HTMLVideoElement = null;
  items = [];
  searchTerm: string = '';
  videoList = [];

  async presentModal(playlist) {
    const modal = await this.modalController.create({
      component: VidModalPage,
      cssClass: 'my-custom-class',
      componentProps: {
        video: playlist,
        title: playlist.snippet.title
      }
    });
    return await modal.present();
  }

  async joinLiveCall() {
    try {
      this.watching = true;
      const uid = this.common.generateUid();
      this.stream.createRTCClient('viewer');
      this.stream.agoraServerEvents(this.stream.rtc);
      await this.stream.localUser(uid, 'viewer');
    } catch (error) {
      console.log(error, 'error');
    }
  }

  FilterVid(event) {
    const dropdownContainer = document.querySelector('#dropdow-container');
    const query = event.target.value.toLowerCase();
    requestAnimationFrame(() => {
      // tslint:disable-next-line: prefer-for-of
      if (dropdownContainer && dropdownContainer.children) {
        for (let i = 0; i < dropdownContainer.children.length; i++) {
          const element = dropdownContainer.children[i].children[1].innerHTML;
          if (query.length === 0) {
            dropdownContainer.children[i].classList.add('hide');
          } else if (element.toLowerCase().indexOf(query) > -1) {
            dropdownContainer.children[i].classList.remove('hide');
          } else {
            dropdownContainer.children[i].classList.add('hide');
          }
        }
      }
    });
  }

  playVid() {
    const options = { playerId: 'youtube-player', playerSize: { width: 600, height: 500 }, videoId: this.globals.vid };
  }
  getMVs() {
    //promise to get data then its data which we assign as an array fille by the get request
    this.http
      .get(this.url, {
        params: {
          part: 'snippet',
          key: environment.apiKey,
          channelId: environment.channel,
          playlistId: environment.musicVids,
          maxResults: '50'
        }
      })
      .toPromise()
      .then((data: any) => {
        Array.prototype.push.apply(this.videoList, data.items);

        this.categories[1].videos = data;
      });
  }

  async getLive() {
    //promise to get data then its data which we assign as an array fille by the get request
    this.http
      .get(this.url, {
        params: {
          part: 'snippet',
          key: environment.apiKey,
          channelId: environment.channel,
          playlistId: environment.live,
          maxResults: '50'
        }
      })
      .toPromise()
      .then((data: any) => {
        Array.prototype.push.apply(this.videoList, data.items);
        this.categories[0].videos = data;
      });
  }

  getInterviews() {
    //promise to get data then its data which we assign as an array fille by the get request
    this.http
      .get(this.url, {
        params: {
          part: 'snippet',
          key: environment.apiKey,
          channelId: environment.channel,
          playlistId: environment.interviews,
          maxResults: '50'
        }
      })
      .toPromise()
      .then((data: any) => {
        Array.prototype.push.apply(this.videoList, data.items);

        this.categories[2].videos = data;
      });
  }

  getPodcast() {
    //promise to get data then its data which we assign as an array fille by the get request
    this.http
      .get(this.url, {
        params: {
          part: 'snippet',
          key: environment.apiKey,
          channelId: environment.channel,
          playlistId: environment.podcast,
          maxResults: '50'
        }
      })
      .toPromise()
      .then((data: any) => {
        Array.prototype.push.apply(this.videoList, data.items);
        this.categories[3].videos = data;
      });
  }

  play(playlist) {
    if (this.globals.isPlaying) {
      this.musicPlayer.reset();
      this.globals.vid =
        'https://www.youtube.com/embed/' +
        playlist.snippet.resourceId.videoId +
        '?enablejsapi=1&origin=http://settrippn.com';
      this.presentModal(playlist);
    } else {
      this.globals.vid =
        'https://www.youtube.com/embed/' +
        playlist.snippet.resourceId.videoId +
        '?enablejsapi=1&origin=http://settrippn.com';
      this.presentModal(playlist);
      //this.backgroundMode.enable();
      //this.musicPlayer.setupBackgroundTasks();
    }
  }

  playVidC(video) {
    if (this.globals.isPlaying) {
      this.musicPlayer.reset();
      //this.backgroundMode.disable();
      // this.musicPlayer.setupBackgroundTasks();
      this.globals.vid = video.snippet.resourceId.videoId;
      //this.youtube.openVideo(this.globals.vid);
      //this.presentModal(video);
    } else {
      this.globals.vid = video.snippet.resourceId.videoId;
      //this.backgroundMode.disable();
      // this.youtube.openVideo(this.globals.vid);
      //this.musicPlayer.setupBackgroundTasks();
      //this.presentModal(video);
    }
  }

  async ngOnInit() {
    this.streamingInfo = await this.stream.getStreamInfo();
    console.log(this.streamingInfo);

    this.getInterviews();
    this.getLive();
    this.getPodcast();
    this.getMVs();
  }
}
