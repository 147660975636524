import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { StoryComponent } from './story/story.component';
import { PricingComponent } from './pricing/pricing.component';
import { PaymentComponent } from './payment/payment.component';
// Import all the components for which navigation service has to be activated

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./log-in/log-in.module').then((m) => m.LogInPageModule)
  },
  {
    path: 'home/:id',
    loadChildren: () => import('./pages/details/details.module').then((m) => m.DetailsPageModule)
  },
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'player',
    loadChildren: () => import('./player/player.module').then((m) => m.PlayerPageModule)
  },
  {
    path: 'vid-modal',
    loadChildren: () => import('./vid-modal/vid-modal.module').then((m) => m.VidModalPageModule)
  },
  {
    path: 'story',
    component: StoryComponent
  },
  { path: 'payment/:productId', component: PaymentComponent },

  // {
  //   path: 'sign-up',
  //   loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpPageModule)
  // },
  /* {
    path: '',
    loadChildren: () => import('./log-in/log-in.module').then(m => m.LogInPageModule),
    canActivate: []
  }, */

  {
    path: 'music2',
    loadChildren: () => import('./music2/music2.module').then((m) => m.Music2PageModule)
  },

  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule)
  },
  {
    path: 'd-modal',
    loadChildren: () => import('./d-modal/d-modal.module').then((m) => m.DModalPageModule)
  },

  {
    path: 'share-modal',
    loadChildren: () => import('./share-modal/share-modal.module').then((m) => m.ShareModalPageModule)
  },
  {
    path: 'forget',
    loadChildren: () => import('./forget/forget.module').then((m) => m.ForgetPageModule)
  },
  {
    path: 'warn',
    loadChildren: () => import('./warn/warn.module').then((m) => m.WarnPageModule)
  },

  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule)
  },
  {
    path: 'lyrics-modal',
    loadChildren: () => import('./lyrics-modal/lyrics-modal.module').then((m) => m.LyricsModalPageModule)
  },
  {
    path: 'comments-modal',
    loadChildren: () => import('./comments-modal/comments-modal.module').then((m) => m.CommentsModalPageModule)
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
