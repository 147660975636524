import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
@Component({
  selector: 'app-payment',
  standalone: true,
  imports: [],
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss',
})
export class PaymentComponent implements OnDestroy {
  // This is your test secret API key.
  checkout: any;
  constructor(private route: ActivatedRoute) {
    this.route.paramMap.subscribe((params) => {
      const productId = params.get('productId');
      console.log('familyId:', productId);
      if (productId) this.initialize(productId);
    });
  }
  ngOnDestroy(): void {
    this.checkout.destroy();
  }

  // Create a Checkout Session
  async initialize(priceId: string) {
    const stripe = await loadStripe(
      'pk_live_51HFk4zJd3fzjfrRu7Rlrq6gDFX0uhuMD4e6r0urJtHioSvL0knYulOQuEnXctbOzRAdvf9PZ7JdnZ8lTRUTWtOnF00CrTxPVVu'
    );

    const fetchClientSecret = async () => {
      const response = await fetch(
        'http://kinkeeper.apps4artists.uk/create-checkout-session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ priceId }),
        }
      );
      const { clientSecret } = await response.json();
      return clientSecret;
    };

    if (stripe) {
      this.checkout = await stripe.initEmbeddedCheckout({
        fetchClientSecret,
      });

      // Mount Checkout
      this.checkout.mount('#checkout');
    }
  }
}
