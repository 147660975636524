import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { getAnalytics, logEvent } from 'firebase/analytics';
@Component({
  selector: 'app-pricing',

  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss'
})
export class PricingComponent implements OnInit {
  authSubscription: any;
  isLoggedIn: boolean = false;
  user: any;
  price1: string = environment.price1;
  price2: string = environment.price2;
  price3: string = environment.price3;
  analytics = getAnalytics();
  constructor(private router: Router) {}
  ngOnInit(): void {
    logEvent(this.analytics, 'page-view', { page: 'pricing' });
  }

  async buy(plan: number) {
    const isLoggedInFromStorage = (await localStorage.getItem('isLoggedIn')) === 'true';
    if (isLoggedInFromStorage) {
      switch (plan) {
        case 1:
          logEvent(this.analytics, 'pricing_button_click', { price: '5.00' });
          this.router.navigate(['payment', this.price1]);
          break;

        case 2:
          logEvent(this.analytics, 'pricing_button_click', { price: '50.00' });

          this.router.navigate(['payment', this.price2]);
          break;
        case 3:
          logEvent(this.analytics, 'pricing_button_click', { price: '195.00' });

          this.router.navigate(['payment', this.price3]);
          break;
      }
    } else {
      this.router.navigate(['login']);
    }
  }
}
