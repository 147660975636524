// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  price1: 'price_1Ki15FJd3fzjfrRubDouQ7Im',
  price2: 'price_1P7xTZJd3fzjfrRuxIqBzsnJ',
  price3: '',
  youtube: 'AIzaSyByVPIePX2IsVQ--BKTnz4FqPVtbk4-VNY',
  apiKey: 'AIzaSyByVPIePX2IsVQ--BKTnz4FqPVtbk4-VNY',
  spotifyClientId: '6650e997d9454edbb635bb4435173985',
  backendUrl: 'http://localhost:3000',
  channel: 'UC6G9yjFn3LVJ7MfExL4YU2Q',
  podcast: 'PLnRlWDKv1d2WoJmUChINQt4Cg2rFGi-Mc',
  live: 'PLnRlWDKv1d2WAIMIFo43_wQdIwOwKlZre',
  musicVids: 'PLnRlWDKv1d2XZw8zjSB1do0_9bxROAsY7',
  interviews: 'PLnRlWDKv1d2XtHrPame56ity2dv6cVrIu',
  stripe: 'pk_live_51HFk4zJd3fzjfrRu7Rlrq6gDFX0uhuMD4e6r0urJtHioSvL0knYulOQuEnXctbOzRAdvf9PZ7JdnZ8lTRUTWtOnF00CrTxPVVu',
  firebase: {
    apiKey: 'AIzaSyDkyoF0GDF4bAEw69hRhmHf2w5Nc1ucQOA',
    authDomain: 'toeknee-tee.firebaseapp.com',
    databaseURL: 'https://toeknee-tee-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'toeknee-tee',
    storageBucket: 'toeknee-tee.appspot.com',
    messagingSenderId: '424847802839',
    appId: '1:424847802839:web:037bd22cc6c4403df7cb92',
    measurementId: 'G-ZK21Y1KZH2',
    vapidKey: 'BOViHxrdUZ_ZSM_0ti2RR0z3BQOyeC9DQ1ibzVmbeXNTWazE8XLR-bjrERb7BBi38MrcH2gnwScYoCePwOf8uL8'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
