import { createReducer, on } from '@ngrx/store';
import { userState } from './user.state';
import { updateUser } from './user.actions';

const _userReducer = createReducer(
  userState,
  on(updateUser, (state, { user }) => {
    return {
      ...state,
      user: { ...user }
    };
  })
);

export function userReducer(state: any, action: any) {
  return _userReducer(state, action);
}
