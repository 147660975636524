import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalVariable } from '../globals';
import { Capacitor, Plugins } from '@capacitor/core'; // Native version
import { Device } from '@capacitor/device';
import Hls from 'hls.js';

@Component({
  selector: 'app-vid-modal',
  templateUrl: './vid-modal.page.html',
  styleUrls: ['./vid-modal.page.scss']
})
export class VidModalPage implements OnInit, AfterViewInit {
  currentYear = new Date().getFullYear();
  title;
  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    public sanitizer: DomSanitizer,
    public globals: GlobalVariable
  ) {}

  id: any;
  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.globals.vid);
  }

  @Input() link: string;
  player: any;
  videoId: string = '';
  stopped: boolean = true;
  videoUrl: string = '';
  video: any;

  ngOnInit() {
    const tag = document.createElement('script');

    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    this.videoUrl =
      'https://www.youtube.com/embed/' + this.video.snippet.resourceId.videoId + '&origin=https://settrippn.com';
    let videos = 'https://www.settrippn.com/ye/ye.mp4';
    let vidLink = this.getSanitizedURL();
  }
  ngAfterViewInit() {
    if (Capacitor.getPlatform() === 'web') {
      // this.initializeYoutubePlayerPluginWeb();
    } else {
      // Native
      // this.initializeYoutubePlayerPluginNative();
    }
  }

  ionViewWillEnter() {
    // Access component props in the constructor or ionViewWillEnter lifecycle hook
    this.video = this.navParams.get('video');
  }

  async dismissModal() {
    this.modalController.dismiss();
  }

  getSanitizedURL() {
    return this.sanitizer.bypassSecurityTrustUrl(
      'https://www.youtube.com/embed/' + this.video.snippet.resourceId.videoId
    );
  }
}
