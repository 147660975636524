import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { IonIcon } from '@ionic/angular/standalone';

import { addIcons } from 'ionicons';
import { Observable } from 'rxjs';
import { SpotifyAuthService } from '../services/spotify-auth.service';
import { Browser } from '@capacitor/browser';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-song-settings-modal',
  templateUrl: './song-settings-modal.component.html',
  styleUrls: ['./song-settings-modal.component.scss'],
  imports: [CommonModule]
})
export class SongSettingsModalComponent implements OnInit {
  @Input() albumArt: string;
  @Input() songTitle: string;
  @Input() artistName: string;
  @Input() liked: boolean;
  @Input() released: boolean;
  @Input() comments: boolean;
  @Input() albumName: string;
  @Input() uri: string;
  @Input() apple: string;
  @Input() type: string;

  spotify: any;
  constructor(
    private modalController: ModalController,
    private http: HttpClient,
    private spotifyAuth: SpotifyAuthService,
    private toastController: ToastController
  ) {
    addIcons({
      spotify: 'assets/icon/Spotify_icon.svg'
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  engage(option) {
    this.modalController.dismiss(option);
  }

  async openSpotify() {
    const refreshToken = localStorage.getItem('refresh_Token');
    if (!refreshToken) this.spotifyAuth.authenticate(this.uri, this.type);
    else {
      await this.spotifyAuth.refreshAccessToken(refreshToken, this.type, this.uri);
    }
  }

  get spotifyLabel() {
    return this.released ? 'Add to Spotify' : ' Pre-save on Spotify';
  }

  get musicLabel() {
    return this.released ? 'Add on Apple Music' : ' Pre-save on Apple Music';
  }

  get favoriteLabel() {
    return this.liked ? 'Remove from Favorites' : 'Add to Favorites'; // Conditional label based on liked state
  }

  openApple() {
    Browser.open({ url: this.apple });
  }

  ngOnInit() {}
}
