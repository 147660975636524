import { Component, OnInit } from '@angular/core';
import { GlobalVariable } from './globals';
import { NavController, Platform } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { getAuth, indexedDBLocalPersistence, initializeAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { AuthService } from './services/auth.service';
import { App } from '@capacitor/app';
import { doc, getDoc, getFirestore, initializeFirestore } from 'firebase/firestore';
import { MusicPlayer } from './core/musicPlayer.services';
import { FcmService } from './services/fcm.service';
import { Browser } from '@capacitor/browser';
import { SpotifyAuthService } from './services/spotify-auth.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private musicPlayer: MusicPlayer,
    private storage: StorageService,
    private auth: AuthService,
    public globals: GlobalVariable,
    private platform: Platform,
    public router: Router,
    public nav: NavController,
    private fcm: FcmService,
    private spotify: SpotifyAuthService
  ) {
    this.initializeApp();

    // Get saved data for users
    if (
      localStorage.getItem('fav_albums') == '' ||
      localStorage.getItem('fav_albums') == null ||
      localStorage.getItem('fav_albums') == undefined
    ) {
      this.globals.fav_albums = [];
    } else {
      this.globals.fav_albums = JSON.parse(localStorage.getItem('fav_albums'));
    }

    if (
      localStorage.getItem('fav_tracks') == '' ||
      localStorage.getItem('fav_tracks') == null ||
      localStorage.getItem('fav_tracks') == undefined
    ) {
      this.globals.fav_tracks = [];
    } else {
      this.globals.fav_tracks = JSON.parse(localStorage.getItem('fav_tracks'));
    }
  }
  ngOnInit() {
    App.addListener('appUrlOpen', async (data: any) => {
      const url = data.url;
      // Check if the URL matches your expected pattern
      if (url.includes('com.toekneetee1.app://callback')) {
        await Browser.close();
        const parsedUrl = new URL(url);

        // Get the value of 'code'
        const accessToken = parsedUrl.searchParams.get('code');
        if (accessToken) {
          console.log('Access Token:', accessToken);

          this.spotify.exchangeAuthorizationCode(accessToken).catch((error) => {
            console.error('Failed to exchange authorization code:', error);
          });
        }
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      setTimeout(() => {
        // SplashScreen.hide();
      }, 3000);

      const app = initializeApp(environment.firebase);
      // // Initialize Firebase in the constructor
      const auth = getAuth(app); // Initialize Auth module
      const db = initializeFirestore(app, { experimentalAutoDetectLongPolling: true }); // Initialize Firestore module
      // // this.data.setAuth(auth, app);
      this.auth.setFireDeets(db, auth);
    });
  }
}
