// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.song-settings-modal .modal-header {
  display: flex;
  align-items: center;
}
.song-settings-modal .modal-header .album-artwork {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.song-settings-modal .modal-header h2 {
  margin: 0;
  font-size: 1.2em;
}
.song-settings-modal .modal-header p {
  margin: 0;
  color: grey;
  font-size: 0.9em;
}
.song-settings-modal ion-item-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
.song-settings-modal ion-item {
  --min-height: 40px;
}
.song-settings-modal ion-icon {
  font-size: 1.2em;
}

ion-list {
  border-top: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/app/song-settings-modal/song-settings-modal.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAAN;AAGI;EACE,SAAA;EACA,gBAAA;AADN;AAII;EACE,SAAA;EACA,WAAA;EACA,gBAAA;AAFN;AAME;EACE,gBAAA;EACA,mBAAA;AAJJ;AAOE;EACE,kBAAA;AALJ;AAQE;EACE,gBAAA;AANJ;;AAUA;EACE,2BAAA;AAPF","sourcesContent":[".song-settings-modal {\n  .modal-header {\n    display: flex;\n    align-items: center;\n\n    .album-artwork {\n      width: 100%;\n      height: auto;\n      border-radius: 8px;\n    }\n\n    h2 {\n      margin: 0;\n      font-size: 1.2em;\n    }\n\n    p {\n      margin: 0;\n      color: grey;\n      font-size: 0.9em;\n    }\n  }\n\n  ion-item-divider {\n    margin-top: 10px;\n    margin-bottom: 10px;\n  }\n\n  ion-item {\n    --min-height: 40px;\n  }\n\n  ion-icon {\n    font-size: 1.2em;\n  }\n}\n\nion-list {\n  border-top: 1px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
